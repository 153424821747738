// Sección "Agenda"
import {Container,Row,Col,Button} from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import './Schedule.css';

import fechaIzquierda from './../images/pleca_izquierda-min.png';
import fechaDerecha from './../images/pleca_derecho-min.png';
import fotoExponente from './../images/foto-exponente1.png';
import fotoExponente2 from './../images/foto-exponente2.png';
import vanessaF from './../images/vanessa.png';
import alejandro_corpeño from './../images/alejandro_.png';
import nelson_chicas from './../images/nelson_chicas-01.png';
import josueelaniin from './../images/josue-elaniin-.png';
import EstefaniRuiz from './../images/EstefaniRuiz.png';
import karlaHernandez from './../images/karlaHernandez.png';
import litoibarra from './../images/litoibarra.png';
import gilbertolara from './../images/gilbertolara.png';
import Emilio_Campos from './../images/Emilio_Campos.png';
import RoxanaGonzalez from './../images/RoxanaGonzalez.png';
import Tatiana_Tobar from './../images/Tatiana_Tobar.png';
import Gabriela_Rivera from './../images/Gabriela_Rivera.png';
import Uriel_Hernandez from './../images/Uriel_Hernandez.png';
import erick_saravia from './../images/erick_saravia.png';
import debora from './../images/debora.png';
import lesly from './../images/lesly.png';
import luisa from './../images/luisa.png';
import ale_munoz from './../images/ale_munoz.png';
import edwin_oracle from './../images/edwin_oracle.png';
import patricia from './../images/patricia.png';
import miguel_osegueda from './../images/miguel_osegueda.png';
import adrian from './../images/adrian.png';
import cesar from './../images/cesar.png';
import laura from './../images/laura.png';



import Carousel from 'react-bootstrap/Carousel';
import gabrielatorres from './../images/gabrielatorres.png';

// Animaciones
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const Agenda = () =>{
    return(
        <div className="bgAgenda" id="schedule">
            <Container>
                <Row>
                    <Col md={12}> 
                        <div data-aos="zoom-in" className="contenidoPrincipalAgenda">
                            <img src={fechaIzquierda} className="imgFechasAgenda" alt=""/>
                            <span className="tituloPrincipalAgenda efectoNeonAgenda"> Agenda_</span>
                            <img src={fechaDerecha} className="imgFechasAgenda" alt="" />
                        </div>
                        <a href="https://eventos.hakki.sv/SETSUZOKU/TICONGLECAMP/"> <p className="buttonAD">
                            <Button className="btn buttonIQ">INGRESA AQUÍ</Button>
                        </p></a>
                  
                    </Col>  
                </Row>
                <Row>
                    <Col md={12}>
                    <div>
                        <Table responsive="md">
                           
                            <tbody className="tablaAgenda">
                                <tr data-aos="zoom-in">
                                    <td className="tdFechas">
                                       <div className="containerInfoAgenda">
                                          23_ <br/>
                                          Agosto_ <br/>
                                            <Button variant="primary colorBotonTabla">Lunes</Button>
                                       </div>
                                    </td>
                                    <td>

                                      <Row>
                                        <Col md={4}>
                                            <img src={nelson_chicas} className="img-fluid" className="imgExponente" alt="Desarrollo Front End" />
                                            <img src={nelson_chicas} className="imgExponenteResponsive" alt="Desarrollo Front End" />
                                        </Col>
                                        <Col md={8}>
                                           <div className="horarioAgendaInfo">
                                                09:00 A.M.
                                                <br/> <br/>
                                                <strong>Conferencia Magistral</strong>
                                                <br/>
                                                Expositor: Nelson Chicas <br/>
                                                Págala
                                           </div>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col md={4}>
                                            <img src={EstefaniRuiz} className="img-fluid" className="imgExponente" alt="Desarrollo Front End" />
                                            <img src={EstefaniRuiz} className="imgExponenteResponsive" alt="Desarrollo Front End" />
                                        </Col>
                                        <Col md={8}>
                                           <div className="horarioAgendaInfo">
                                                02:00 P.M.
                                                <br/> <br/>
                                                <strong>Connecting Experiences</strong>
                                                <br/>
                                                Expositora: Estefani Ruiz <br/>
                                                Elaniin 
                                           </div>
                                        </Col>
                                      </Row>

                                    </td>
                                      {/* Tarde */}
                                    <td>
                                      <Row>
                                        <Col md={4}>
                                          <p className="EPFE"></p>
                                            {/* <img src={fotoExponente} className="img-fluid" className="imgExponente" alt="Desarrollo Front End" /> */}
                                            {/* <img src={fotoExponente} className="imgExponenteResponsive" alt="Desarrollo Front End" /> */}
                                        </Col>
                                        <Col md={8}>
                                           <div className="horarioAgendaInfo">
                                               
                                                <br/> <br/>
                                                <br/> <br/>
                                                <strong>   </strong>
                                                <br/>
                                                
                                                
                                           </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col md={4}>
                                            <img src={RoxanaGonzalez} className="img-fluid" className="imgExponente" alt="Desarrollo Front End" />
                                            <img src={RoxanaGonzalez} className="imgExponenteResponsive" alt="Desarrollo Front End" />
                                        </Col>
                                        <Col md={8}>
                                        <div className="horarioAgendaInfo">
                                                02:00 P.M.
                                                <br/> <br/>
                                                <strong>HTML + CSS Basics  </strong>
                                                {/* <strong>Por Confirmar </strong> */}
                                                <br/>
                                                Expositora: Roxana González <br/>
                                                NovaTech 
                                           </div>
                                        </Col>
                                      </Row>
                                    </td>
                                </tr>



                                <tr data-aos="zoom-in">
                                    <td className="tdFechas">
                                       <div className="containerInfoAgenda">
                                          24_ <br/>
                                          Agosto_ <br/>
                                            <Button variant="primary colorBotonTabla">Martes</Button>
                                       </div>
                                    </td>
                                    <td>

                                      <Row>
                                        <Col md={4}>
                                            <img src={vanessaF} className="img-fluid" className="imgExponente" alt="Desarrollo Front End" />
                                            <img src={vanessaF} className="imgExponenteResponsive" alt="Desarrollo Front End" />
                                        </Col>
                                        <Col md={8}>
                                           <div className="horarioAgendaInfo">
                                                09:00 A.M.
                                                <br/> <br/>
                                                <strong>Creando una aplicación web <br/> ¿Librería o Framework?</strong>
                                                <br/>
                                                Expositora: Vanessa Aristizabal <br/>
                                                Gorilla Logic
                                           </div>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col md={4}>
                                            <img src={luisa} className="img-fluid" className="imgExponente" alt="Desarrollo Front End" />
                                            <img src={luisa} className="imgExponenteResponsive" alt="Desarrollo Front End" />
                                        </Col>
                                        <Col md={8}>
                                           <div className="horarioAgendaInfo">
                                                02:00 P.M.
                                                <br/> <br/>
                                                <strong>Da tus primeros pasos en la nube con AWS </strong><br/>
                                                Expositora: Luisa Vesga  <br/>
                                                AWS
                                           </div>
                                        </Col>
                                      </Row>

                                    </td>
                                    {/* Segunda Fila */}
                                    <td>
                                      <Row>
                                        <Col md={11}>
                                        <Carousel fade>
                                              <Carousel.Item interval={1000}>
                                                <img
                                                  className="img-fluid"
                                                  src={debora}
                                                  alt="First slide"
                                                  // width="35%"
                                                />
                                              </Carousel.Item>
                                              <Carousel.Item interval={1000}>
                                                <img
                                                  className="img-fluid"
                                                  src={lesly}
                                                  alt="Second slide"
                                                />
                                              </Carousel.Item>

                                            </Carousel>
                                            <div className="horarioAgendaInfo SpaceInfo">
                                             
                                             09:00 A.M.
                                             <br/> <br/>
                                             {/* <strong> Bases de Datos Oracle  </strong> */}
                                             <strong>Diseño y prototipado UI/UX para Apps con Figma  <br/></strong>
                                             Expositoras: Debora Lievano y Lesly Lemus <br/>
                                             Norttech Labs
                                        </div>
                                            {/* <img src={fotoExponente} className="img-fluid" className="imgExponente" alt="Desarrollo Front End" /> */}
                                            {/* <img src={fotoExponente} className="imgExponenteResponsive" alt="Desarrollo Front End" /> */}
                                        </Col>
                                        
                                      </Row>
                                      <Row>
                                        <Col md={4}>
                                          
                                            <img src={josueelaniin} className="img-fluid" className="imgExponente" alt="Desarrollo Front End" />
                                            <img src={josueelaniin} className="imgExponenteResponsive" alt="Desarrollo Front End" />
                                        </Col>
                                        <Col md={8}>
                                           <div className="horarioAgendaInfo">
                                                02:00 P.M.
                                                <br/> <br/>
                                                <strong>TailwindCSS World</strong>
                                                <br/>
                                                Expositor: Josue Ayala <br/>
                                                Elaniin 
                                           </div>
                                        </Col>
                                      </Row>
                                    </td>
                                </tr>
                                <tr data-aos="zoom-in">
                                    <td className="tdFechas">
                                       <div className="containerInfoAgenda">
                                          25_ <br/>
                                          Agosto_ <br/>
                                            <Button variant="primary colorBotonTabla">Miércoles</Button>
                                       </div>
                                    </td>
                                    <td>

                                      <Row>
                                        <Col md={4}>
                                            <img src={Uriel_Hernandez} className="img-fluid" className="imgExponente" alt="Desarrollo Front End" />
                                            <img src={Uriel_Hernandez} className="imgExponenteResponsive" alt="Desarrollo Front End" />
                                        </Col>
                                        <Col md={8}>
                                           <div className="horarioAgendaInfo">
                                                09:00 A.M.
                                                <br/> <br/>
                                                <strong>Introducción a Flexbox y Grid</strong>
                                                <br/>
                                                Expositor: Uriel Hernández <br/>
                                                Código Facilito
                                           </div>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col md={4}>
                                            <img src={erick_saravia} className="img-fluid" className="imgExponente" alt="Desarrollo Front End" />
                                            <img src={erick_saravia} className="imgExponenteResponsive" alt="Desarrollo Front End" />
                                        </Col>
                                        <Col md={8}>
                                           <div className="horarioAgendaInfo">
                                                02:00 P.M.
                                                <br/> <br/>
                                                {/* <strong>Bootstrap </strong> */}
                                                <strong>Programación y Continuous Integration CI/CD en Google Cloud. <br/> </strong>
                                                Expositor: Erick Saravia<br/>
                                                Norttech Labs
                                           </div>
                                        </Col>
                                      </Row>

                                    </td>

                                    <td>
                                      <Row>
                                        <Col md={4}>
                                            <img src={miguel_osegueda} className="img-fluid" className="imgExponente" alt="Desarrollo Front End" />
                                            <img src={miguel_osegueda} className="imgExponenteResponsive" alt="Desarrollo Front End" />
                                        </Col>
                                        <Col md={8}>
                                           <div className="horarioAgendaInfo">
                                                09:00 A.M.
                                                <br/> <br/>
                                                <strong> Empresas Nativas Digitales  <br/> </strong>
                                                Expositor: Miguel Osegueda <br/>
                                                Microsoft
                                           </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col md={4}>
                                            <img src={Emilio_Campos} className="img-fluid" className="imgExponente" alt="Desarrollo Front End" />
                                            <img src={Emilio_Campos} className="imgExponenteResponsive" alt="Desarrollo Front End" />
                                        </Col>
                                        <Col md={8}>
                                           <div className="horarioAgendaInfo">
                                                02:00 P.M.
                                                <br/> <br/>
                                                <strong> Base de Datos Convergentes <br/> (multiproposito) para Desarrolladores</strong>
                                                <br/>
                                                Expositor: Emilio Campos<br/>
                                                Oracle

                                                {/* Por Confirmar */}
                                           </div>
                                        </Col>
                                      </Row>
                                    </td>
                                </tr>

                                <tr data-aos="zoom-in">
                                    <td className="tdFechas">
                                       <div className="containerInfoAgenda">
                                          26_ <br/>
                                          Agosto_ <br/>
                                            <Button variant="primary colorBotonTabla">Jueves</Button>
                                       </div>
                                    </td>
                                    <td>

                                      <Row>
                                        <Col md={4}>
                                            <img src={Tatiana_Tobar} className="img-fluid" className="imgExponente" alt="Desarrollo Front End" />
                                            <img src={Tatiana_Tobar} className="imgExponenteResponsive" alt="Desarrollo Front End" />
                                        </Col>
                                        <Col md={8}>
                                        <div className="horarioAgendaInfo">
                                                09:00 A.M.
                                                <br/> <br/>
                                                <strong>Cómo ser un QA y no morir en el intento</strong>
                                                {/* <strong>Por Confirmar  <br/> </strong> */}
                                                <br/>
                                                Expositora: Tatiana Tobar  <br/>
                                                Creativa Consultores
                                                {/*  */}
                                                
                                           </div>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col md={4}>
                                            <img src={alejandro_corpeño} className="img-fluid" className="imgExponente" alt="Escalabilidad de plataformas de backend" />
                                            <img src={alejandro_corpeño} className="imgExponenteResponsive" alt="Escalabilidad de plataformas de backend" />
                                        </Col>
                                        <Col md={8}>
                                           <div className="horarioAgendaInfo">
                                                02:00 P.M.
                                                <br/> <br/>
                                                <strong>Escalabilidad de plataformas <br/> de backend</strong>
                                                {/* <strong>Por Confirmar </strong> */}
                                                <br/>                                                
                                                Expositor: Alejandro Corpeño <br/>
                                                Hello Iconic
                                           </div>
                                        </Col>
                                      </Row>

                                    </td>

                                    <td>
                                      <Row>
                                        <Col md={4}>
                                            <img src={cesar} className="img-fluid" className="imgExponente" alt="Desarrollo Front End" />
                                            <img src={cesar} className="imgExponenteResponsive" alt="Desarrollo Front End" />
                                        </Col>
                                        <Col md={8}>
                                           <div className="horarioAgendaInfo">
                                                09:00 A.M.
                                                <br/> <br/>
                                                <strong>Testing Automatizado  <br/> </strong>
                                                
                                                Expositor: Cesar Araujo <br/>
                                                Creativa Consultores
                                           </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col md={4}>
                                            <img src={edwin_oracle} className="img-fluid" className="imgExponente" alt="Desarrollo Front End" />
                                            <img src={edwin_oracle} className="imgExponenteResponsive" alt="Desarrollo Front End" />
                                        </Col>
                                        <Col md={8}>
                                           <div className="horarioAgendaInfo">
                                                02:00 P.M.
                                                <br/> <br/>
                                                <strong>Nuevos entornos de microservicios, <br/> arquitecturas híbridas sobre contenedores  <br/> </strong>
                                                Expositor: Edwin Spencer  <br/>
                                                Oracle
                                                
                                           </div>
                                        </Col>
                                      </Row>
                                    </td>
                                </tr>
                               
                                <tr data-aos="zoom-in">
                                    <td className="tdFechas">
                                       <div className="containerInfoAgenda">
                                          27_ <br/>
                                          Agosto_ <br/>
                                            <Button variant="primary colorBotonTabla">Viernes</Button>
                                       </div>
                                    </td>

                                    <td>
                                      <Row className="justify-content-center">
                                      <Col col={12}>
                                        <Carousel fade>
                                              <Carousel.Item interval={1000}>
                                                <img
                                                  className="img-fluid"
                                                  src={RoxanaGonzalez}
                                                  alt="First slide"
                                                />
                                              </Carousel.Item>

                                              <Carousel.Item interval={1000}>
                                                <img
                                                  className="img-fluid"
                                                  src={karlaHernandez}
                                                  alt="Second slide"
                                                />
                                              </Carousel.Item>

                                            
                                              
                                              <Carousel.Item interval={1000}>
                                                <img
                                                  className="img-fluid"
                                                  src={Gabriela_Rivera}
                                                  alt="Third slide"
                                                />
                                              </Carousel.Item>
                                              <Carousel.Item interval={1000}>
                                                <img
                                                  className="img-fluid"
                                                  src={laura}
                                                  alt="Third slide"
                                                />
                                              </Carousel.Item>

                                              <Carousel.Item interval={1000}>
                                                <img
                                                  className="img-fluid"
                                                  src={ale_munoz}
                                                  alt="Third slide"
                                                />
                                              </Carousel.Item>
                                          
                                              

                                              
                                            </Carousel>
                                            </Col>
                                      </Row>

                                      <Row className="justify-content-center">
                                       <Col col={12}>
                                       {/* <Carousel fade> */}
                                       <Carousel fade>
                                         
                                       <Carousel.Item interval={1000}>
                                                <img
                                                  className="img-fluid"
                                                  src={adrian}
                                                  alt="Second slide"
                                                />
                                          </Carousel.Item>

                                              <Carousel.Item interval={1000}>
                                                <img
                                                  className="img-fluid"
                                                  src={gilbertolara}
                                                  alt="Third slide"
                                                />
                                              </Carousel.Item>

                                              <Carousel.Item interval={1000}>
                                                <img
                                                  className="img-fluid"
                                                  src={gabrielatorres}
                                                  alt="Third slide"
                                                />
                                              </Carousel.Item>

                                              <Carousel.Item interval={1000}>
                                                <img
                                                  className="img-fluid"
                                                  src={patricia}
                                                  alt="Third slide"
                                                />
                                              </Carousel.Item>

                                              <Carousel.Item interval={1000}>
                                                <img
                                                  className="img-fluid"
                                                  src={litoibarra}
                                                  alt="First slide"
                                                  // width="35%"
                                                />
                                              </Carousel.Item>

                                            </Carousel>
                                       </Col>
                                      </Row>

                                     

                                    </td>

                                    <td>
                                    <Row>
                                        <Col md={12} className="ConMar">
                                        <div className="horarioAgendaInfo">
                                                09:00 A.M.
                                                <br/> <br/>
                                                {/* <strong>Mujeres y Tecnología </strong> */}
                                                <strong>Conversatorio </strong>
                                                <br/>
                                                Participantes: Roxana González, Karla Hernández, Gabriela Rivera, Laura Hernández <br/>
                                                Modera: Alejandra Muñoz <br/>
                                                Conversatorio sobre retos y oportunidades para las Mujeres en las carreras TIC
                                           </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col md={12} className="ConMar">
                                           <div className="horarioAgendaInfo">
                                                02:00 P.M.
                                                <br/> <br/>
                                                <strong>Conversatorio</strong>
                                                {/* <strong>Oportunidades en el mundo de las TICs </strong> */}
                                                <br/>
                                                Participantes: Adrián Gómez, Gilberto Lara, Gabriela Torres, Patricia Flamenco <br/>
                                                Modera: Lito Ibarra <br/>
                                                Hacer visibles las oportunidades de trabajo para los Developers
                                           </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col md={12} className="ConMar">
                                        <div className="horarioAgendaInfo">
                                                03:30 P.M.
                                                <br/> <br/>
                                                <strong>Clausura y becas </strong>
                                                <br/>
                                                Patrocinadores y organizadores
                                          </div>
                                        </Col>
                                      </Row>
                                    </td>



                                </tr>

                            </tbody>
                        </Table>
                        
                    </div>
                    </Col>
                </Row>
                <a href="https://www.youtube.com/playlist?list=PL1v-Ew-fdkTCc8eeCnO4YP9dtX2zU-uUf" target="_blank"> <p className="buttonAD">
                            <Button className="btn buttonIQ">INGRESA AQUÍ</Button>
                        </p></a>
                <div className="filaexAD row "> </div>
            </Container>
        </div>
    )
}

export default Agenda 