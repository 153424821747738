// Sección "Patrocinadores"
import {Container,Row, Col} from 'react-bootstrap'
import './Sponsors.css'

import Sponsor1 from './../images/svnet.png'
// import Sponsor4 from './../images/svnet-01.png';
import Sponsor2 from './../images/ticongle agency-01.png';
import Sponsor3 from './../images/isoc-01.png';
import Sponsor5 from './../images/tigo-01.png';
import Sponsor6 from './../images/codigo-facilito.png';




// import Sponsor2 from './../images/logo_2-min.png'
// import Sponsor3 from './../images/logo_3-min.png'
// import Sponsor4 from './../images/logo_4-min.png'
// import Sponsor5 from './../images/logo_5-min.png'
// import Sponsor6 from './../images/logo_6-min.png'
// import Sponsor7 from './../images/logo_7-min.png'
// import Sponsor8 from './../images/logo_8-min.png'
// import Sponsor9 from './../images/logo_9-min.png'

import colaboradores_1 from './../images/creativa.png';
import colaboradores_2 from './../images/oracle.png';
import colaboradores_3 from './../images/ticongle academy-01.png';
import colaboradores_5 from './../images/elaniin-01.png';
import colaboradores_4 from './../images/pagala-01.png';
import colaboradores_6 from './../images/mido-01.png';
import colaboradores_7 from './../images/secre de innovacion.png';
import colaboradores_8 from './../images/hello-iconic-01.png';
import colaboradores_9 from './../images/nova_tech.png';


import ugb from './../images/ugb.png';
import roots from './../images/roots.png';
import aws from './../images/aws.png';
import geekgirls from './../images/geekgirls.png';
import tech_lovers from './../images/tech_lovers.png';
import ujmd from './../images/ujmd.png';
import microsoft from './../images/microsoft.png';
import anortech from './../images/anortech.png';
import usam from './../images/usam.png';
import tiankii from './../images/tiankii.png';
import ecssa from './../images/ecssa.png';






// Animaciones
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const Sponsors = () =>{
    return(
        <div className="bgSponsors" id="sponsors">
{/*             
            <h1 class="tituloPatrocinadores">
                Patrocinadores_
            </h1> */}

            <Container fluid className="text-center">
                <Row 
                data-aos="fade-up"
                data-aos-duration="2000">
                    {/* <Col md={12}>
                        <h4 class="titulosPatrocinadoresPremium">
                            _Patrocinadores_Premium_
                        </h4>
                     </Col> */}
                    
                    <Col md={12}>
                       <img src={colaboradores_7} width="23%" className="img-fluid ISX" alt="Patrocinadores" />
                       
                       <img src={microsoft} width="20%" className="img-fluid ISX" alt="Patrocinadores" />
                       <img src={Sponsor5} width="13%" className="img-fluid ISX" alt="Patrocinadores" />
                       <img src={aws} width="20%" className="img-fluid ISX" alt="Patrocinadores" />
                       
                    </Col>
                        
                    <Col md={12}>
                        
                       <img src={Sponsor1} width="17%" className="img-fluid ISX" alt="Patrocinadores" />
                       <img src={colaboradores_5} width="20%" className="img-fluid ISX" alt="Patrocinadores" />
                       <img src={Sponsor6} width="24%" className="img-fluid ISX" alt="Patrocinadores" />
                       <img src={Sponsor3} width="20%" className="img-fluid ISX" alt="Patrocinadores" />
                       
                    </Col>
                    
                    <Col md={12}>
                      
                       <img src={Sponsor2} width="21%" className="img-fluid ISX" alt="Patrocinadores" />
                       <img src={colaboradores_6} width="15%" className="img-fluid ISX" alt="Patrocinadores" />
                       <img src={colaboradores_1} width="18%" className="img-fluid ISX" alt="Patrocinadores" />
                       <img src={ecssa} width="18%" className="img-fluid ISX" alt="Patrocinadores" />
                       

                    </Col>
                    

                    {/* <Col md={12}> */}
                        {/* <img src={Sponsor5} width="25%" className="img-fluid ISX" alt="Patrocinadores" /> */}
                    {/* </Col> */}

                </Row>

                
                {/* <Row
                data-aos="fade-up"
                data-aos-duration="2000"
                >
                    <Col md={12}>
                        <h4 class="titulosPatrocinadores">
                            _Patrocinadores_Plata_
                        </h4>
                    </Col>
            
                    <Col md={12}>
                       <img src={Sponsor4} width="65%" className="img-fluid" alt="Patrocinadores" />
                    </Col>
                </Row> */}

                {/* <Row
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    >
                        <Col md={12}>
                            <h4 class="titulosPatrocinadores">
                                _Patrocinadores_Bronce_
                            </h4>
                        </Col>
                        <Col md={12}>
                            <img src={Sponsor9} width="65%" className="img-fluid" alt="Patrocinadores" />
                        </Col>
                        
                </Row> */}
                <Row
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    >
                        <Col md={12}>
                            <h4 class="titulosPatrocinadores">
                                _Colaboradores_
                            </h4>
                        </Col>
                        
                        <Col md={12} className="imgSponsors">
                            <img src={colaboradores_2} width="18%" className="img-fluid ISX" alt="Patrocinadores" />
                            <img src={colaboradores_3} width="18%" className="img-fluid ISX" alt="Patrocinadores" />
                            <img src={colaboradores_4} width="18%" className="img-fluid ISX" alt="Patrocinadores" />
                            <img src={colaboradores_8} width="18%" className="img-fluid ISX" alt="Patrocinadores" />
                            <img src={roots} width="20%" className="img-fluid ISX" alt="Patrocinadores" />
                           

                        </Col>
                        
                        <Col md={12} className="imgSponsors">
                            <img src={geekgirls} width="20%" className="img-fluid ISX" alt="Patrocinadores" />
                            <img src={colaboradores_9} width="20%" className="img-fluid ISX" alt="Patrocinadores" />
                            <img src={anortech} width="20%" className="img-fluid ISX" alt="Patrocinadores" />
                            <img src={tiankii} width="20%" className="img-fluid ISX" alt="Patrocinadores" />
                            <img src={tech_lovers} width="20%" className="img-fluid ISX" alt="Patrocinadores" /> 
                            

                            
                        </Col>
                        
                        <Col md={12} className="imgSponsors">
                               
                            <img src={ugb} width="20%" className="img-fluid ISX" alt="Patrocinadores" />
                            <img src={ujmd} width="20%" className="img-fluid ISX " alt="Patrocinadores" />
                            <img src={usam} width="22%" className="img-fluid ISX " alt="Patrocinadores" />
    
                        </Col>
                    
                        
                </Row>
        
                <div class="filaExtra row"></div>

            </Container>    
        </div>
    )
}

export default Sponsors;