import { Navbar, Nav } from 'react-bootstrap'

import ModalForms from './modalFormulario';
import conexion from '../images/conexion.png'
import ticongle from '../images/ticongle.png'

import './Navigation.css'

const Navigation = () => {
  
    return (
        <Navbar className="bg-bootcamp" collapseOnSelect expand="md" variant="dark" fixed="top">
            <Navbar.Brand href="http://www.conexion.sv" target="_blank" className="LogosResponsive">
                <img
                    alt=""
                    src={ conexion }
                    className="d-inline-block h-brand"
                />
            </Navbar.Brand>
            <Navbar.Brand href="https://ticongle.sv" target="_blank" className="LogosResponsive">
                <img
                    alt=""
                    src={ ticongle }
                    className="d-inline-block h-brand-ticongle"
                />
            </Navbar.Brand>
            <div className="menuResponsive">
            <Navbar.Toggle aria-controls="responsive-navbar-nav " />
            </div>
           
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto"></Nav>
                <Nav className="text-center">
                    <Nav.Link className="pr-4" href="#header">Inicio</Nav.Link>
                    <Nav.Link className="pr-4" href="#about">Acerca de</Nav.Link>
                    <Nav.Link className="pr-4" href="#program">Sesiones</Nav.Link>
                    <Nav.Link className="pr-4" href="#schedule">Agenda</Nav.Link>
                    {/* <Nav.Link className="pr-4" href="#codeofConduct">Regístrate </Nav.Link> */}
                    <Nav.Link className="pr-4" href="https://drive.google.com/drive/folders/1CVKyS1yZrLtp4nF1EHBNTf9q9Pb3_QKj?usp=sharing" target="_blank">Media Kit</Nav.Link>   
                    {/* <Nav.Link className="pr-4" href="#contact">Media Kit</Nav.Link>    */}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Navigation

