
import React, { useState, useEffect } from 'react';
import ModalForms from './modalFormulario';
import { Button } from 'react-bootstrap'
import { Container,Row,Col } from 'react-bootstrap'

import bootcamp from '../images/ticongle-bootcamp.png'
// import bgheader from '../images/bg-section-1.jpg'
import './Header.css';

// import ReactGA from 'react-ga';
// Animaciones
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();


const Header = () => {
    
        
    // useEffect(() => {
    //     ReactGA.initialize('G-XDN5Y9TJRY');
    //     ReactGA.pageview('/');
    // }, []);
       
    return (
        <div id="header" className="bg-header-bootcamp d-flex align-items-center justify-flex-end">
            <Container>
            <Row>
                <Col className="imgHeader pthim">
                    <div 
                    data-aos="fade-left"
                    data-aos-anchor="#example-anchor"
                    data-aos-offset="500"
                    data-aos-duration="700" 
                    >
                        <img data-aos="fade-in" src={ bootcamp } width="550" alt="Bootcamp Web Developer - CONEXION/TICONGLE - Formación acelerada en las diferentes áreas de la industria del desarrollo de software" className="img-fluid" />
                       {/* <a href="#codeofConduct"> <p className="buttonHeader"><Button className="btn btn-register">Aplica y regístrate</Button></p></a> */}
                       <a href="https://www.youtube.com/playlist?list=PL1v-Ew-fdkTCc8eeCnO4YP9dtX2zU-uUf" target="_blank"> <p className="buttonHeader">
                            <Button className="btn buttonIQ">INGRESA AQUÍ</Button>
                        </p></a>
                        {/* <a href="https://eventos.hakki.sv/SETSUZOKU/TICONGLECAMP/"> <p className="buttonHeader">
                            <Button className="btn buttonIQ">INGRESA AQUÍ</Button>
                        </p></a> */}
                    {/* <p className="buttonHeader"><ModalForms /> </p> */}
                    </div>
                </Col>
            </Row>
         

            </Container>
        </div>
    );
}

export default Header
