
import {Container,Row,Col} from 'react-bootstrap'

import form1 from '../images/about-form-pink.png'
import form2 from '../images/about-form-lightblue.png'
import './premios.css'


import aws from './../images/aws.png';
import codigoFacilito from './../images/codigo-facilito.png';

// Animaciones
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();


const premios = () =>{
    return(
        <div className="bgCodigoConducta" id="codeofConduct">
        <Container> 
             <div 
             data-aos="fade-up"
             data-aos-anchor-placement="center-bottom"
             >
                <h1 className="tituloCodigoPrmeios"> 
                    <span>Premios</span>
                </h1>
            </div>

            <Row>
                <Col md={6} >
                      <div className="textInfoSectionPremios" data-aos="zoom-out-right" >
                      {/* <img
                            src={ form1 }
                            width="100"
                            height="90"
                            alt="Código de conducta"
                            className="imgLineaCodig"
                        /> */}
                        <p>
                        Todos los participantes recibirán un crédito de $1,000.00 para crear Servidores y hacer Deploy de Aplicaciones en la nube Amazon Web Services
                        </p>
                         <img src={aws} width="50%" className="img-fluid " alt="Patrocinadores" />

                     </div>
                </Col>
                <Col md={6}>
                <div className="textInfoSectionPremios" data-aos="zoom-out-right"
                         data-aos-easing="ease-out-cubic"
                         data-aos-duration="2000">
                        {/* <img
                            src={ form2 }
                            width="100"
                            height="90"
                            alt="Código de conducta"
                            className="imgLineaCodig"
                        /> */}
                        <p>
                            Al participar en el TICONGLE CAMP podrás acceder a 200+ becas de código facilito en la especialidad que prefieras.
                        </p>
                        <img src={codigoFacilito} width="60%" className="img-fluid CFIMG" alt="Patrocinadores" />     
                    </div>
                </Col>
                <Col md={6}>
                </Col>
                <Col md={6}>   
                </Col>
            </Row>
                       
        </Container>
    </div>
    )
}

export default premios