// Sección "Programa del Bootcamp"
import {Container,Row,Col,ListGroup} from 'react-bootstrap';

import './Program.css';
import fechaIzquierda from './../images/pleca_izquierda-min.png';
import fechaDerecha from './../images/pleca_derecho-min.png';
import formacion from './../images/reloj_programa-min.png';

import vinculacion from './../images/enlace_programa-min.png';
import talleres from './../images/live_expertos-min.png';
import expertos from './../images/expertos_program-min.png';
import becas from './../images/becas_programa-min.png';

import arrowleft from '../images/pleca_izquierda.png'
import arrowright from '../images/pleca_derecha.png'

// Animacion
import 'animate.css';
import Typist from 'react-text-typist'
const ProgramBootCamp = ()  =>{
    return(
        <div className="bgSectionProgram" id="program">
               <Container>
                    <Row>
                        <Col md={12}> 
                            <div className="contenidoPrincipal">
                                <img src={fechaIzquierda} className="imgFechas" alt="Programa_del_Bootcamp"/>
                                <span className="tituloPrincipal efectoNeon"> Sesiones_del_Bootcamp</span>
                                <img src={fechaDerecha} className="imgFechas" alt="Programa_del_Bootcamp"/>
                                <div className="text-lefts about-header" 
                                data-aos="fade-up"
                                data-aos-anchor-placement="center-center">
                            </div>
                            </div>
                            
                            <div className="contenidoPrincipal2">
                                    <h1 className="efectoNeon" >
                                        <img src={ arrowleft } className="arrow" alt="Programa_del_Bootcamp"/><span>Programa del_</span>
                                    </h1>
                                    <h1 className="text-right efectoNeon">
                                        <span>Bootcamp</span>
                                        <img src={ arrowright } className="arrow" alt="Programa_del_Bootcamp" />
                                    </h1>
                            </div>
                          
                        </Col>  
                    </Row>
                    <Row className="listaDeProgramas">
                        <Col md={6}>
                            <ListGroup>
                                <ListGroup.Item> 
                                    <p className="lineaClasesBootCamp">
                                        <span className="clasesBootCamp">Clase 
                                         <br 
                                         className="saltoResponsive"/>
                                          </span>  
                                           <Typist 
                                            sentences={[
                                                'Diseño de Base de Datos'
                                            ]}
                                            loop={true}
                                             typingSpeed={280}
                                          />
                                    </p> 
                                </ListGroup.Item>
                                <ListGroup.Item> 
                                    <p className="lineaClasesBootCamp">
                                        <span className="clasesBootCamp">Clase 
                                         <br 
                                         className="saltoResponsive"/>
                                         </span> 
                                         <Typist
                                            sentences={[
                                                'Diseño UX-UI'
                                            ]}
                                            loop={true}
                                             typingSpeed={280}
                                          />
                                         </p> 
                                </ListGroup.Item>
                                <ListGroup.Item> 
                                    <p className="lineaClasesBootCamp">
                                        <span className="clasesBootCamp">Clase 
                                         <br 
                                         className="saltoResponsive"/>
                                         </span>
                                         <Typist
                                            sentences={[
                                                'Desarrollo Front End'
                                            ]}
                                            loop={true}
                                             typingSpeed={280}
                                          />
                                          </p> 
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col md={6}>
                            <ListGroup>
                            <ListGroup.Item> 
                                <p className="lineaClasesBootCamp">
                                    <span className="clasesBootCamp">Clase 
                                     <br 
                                     className="saltoResponsive"/>
                                     </span>
                                     <Typist
                                            sentences={[
                                                ' Desarrollo Back End'
                                            ]}
                                            loop={true}
                                             typingSpeed={280}
                                          />
                                     </p> 
                            </ListGroup.Item>
                            <ListGroup.Item> 
                                <p className="lineaClasesBootCamp">
                                    <span className="clasesBootCamp">Clase 
                                     <br 
                                     className="saltoResponsive"/>
                                     </span> 
                                     <Typist
                                        sentences={[
                                            'Infraestructura'
                                        ]}
                                        loop={true}
                                         typingSpeed={280}
                                      />
                                     </p> 
                            </ListGroup.Item>
                            <ListGroup.Item> 
                                <p className="lineaClasesBootCamp">
                                    <span className="clasesBootCamp">Clase 
                                     <br 
                                     className="saltoResponsive"/>
                                     </span>
                                     <Typist
                                        sentences={[
                                            'Testing'
                                        ]}
                                        loop={true}
                                         typingSpeed={280}
                                      />
                                      </p> 
                            </ListGroup.Item>

                            </ListGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="buttonSectionBoomCam">
                            {/* <button type="button" className="btn btn-register-button-bootcamp btn btn-primary">Descargar Programa</button> */}
                        </Col>
                    </Row>
                  
                    <div className="bgImgSection2">
                    <h1 className="tituloComponente">_Componentes_</h1>  
                        <Row>       
                            <Col md={2} >
                                <p className="animate__animated animate__bounce animate__infinite" ><img src={formacion} className="img-fluid" alt="Formación acelerada" /></p>
                                <h5 className="programasBootcamp">Formación acelerada</h5>
                            </Col>
                            <Col md={3} >
                                <p className="animate__animated animate__bounce animate__infinite"><img src={vinculacion} className="img-fluid" alt="Vinculación con empresa IT" /> </p>
                                <h5 className="programasBootcamp">Vinculación con empresa IT</h5>
                            </Col>
                            <Col md={2} >
                                <p className="animate__animated animate__bounce animate__infinite"> <img src={talleres} className="img-fluid" alt="Talleres en vivo" /></p>
                                <h5 className="programasBootcamp">Talleres en vivo</h5>
                            </Col>
                            <Col md={3} >
                                <p className="animate__animated animate__bounce animate__infinite"><img src={expertos} className="img-fluid" alt="Expertos en Web Development" /></p>
                                <h5 className="programasBootcamp">Expertos en Web Development</h5>
                            </Col>
                            <Col md={2} >
                                <p className="animate__animated animate__bounce animate__infinite"><img src={becas} className="img-fluid" alt="Becas en áreas IT" /></p>
                                <h5 className="programasBootcamp">Becas en áreas IT</h5>
                            </Col>
                        </Row>
                    </div>
                   



                   <Row className="filaExtra"></Row>
                
                </Container>

        </div>

    );
}

export default ProgramBootCamp