
import {Container,Row,Col} from 'react-bootstrap'

import form1 from '../images/about-form-pink.png'
import form2 from '../images/about-form-lightblue.png'
import './CodeOfConduct.css'

// Animaciones
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();


const Conduct = () =>{
    return(
        <div className="bgCodigoConducta" id="codeofConduct">
        <Container> 
             <div 
             data-aos="fade-up"
             data-aos-anchor-placement="center-bottom"
             >
                <h1 className="tituloCodigoConducta"> 
                    <span>Código_</span>
                        <br/>
                    <span className="textConducta">de conducta_</span>
                </h1>
            </div>

            <Row>
                <Col md={6} >
                      <div className="textInfoSection" data-aos="zoom-out-right" >
                      <img
                            src={ form1 }
                            width="100"
                            height="130"
                            alt="Código de conducta"
                            className="imgLineaCodig"
                        />
                        <p>
                        Los organizadores somos responsables de clarificar los estándares de comportamiento aceptable y tomaremos medidas correctivas y apropiadas en respuesta a situaciones de conducta inaceptable. Nos comprometemos a hacer que la participación en el bootcamp sea una experiencia inclusiva.
                        </p>
                     </div>
                </Col>
                <Col md={6}> </Col>
                <Col md={6}></Col>
                <Col md={6}>
                    <div className="textInfoSection"data-aos="flip-left"
                         data-aos-easing="ease-out-cubic"
                         data-aos-duration="2000">
                        <img
                            src={ form2 }
                            width="100"
                            height="130"
                            alt="Código de conducta"
                            className="imgLineaCodig"
                        />
                        <p>
                        Los participantes nos comprometemos a crear un ambiente empático y positivo, usando un lenguaje amable e inclusivo, respetando los diferentes puntos de vista y experiencias, aceptando críticas constructivas y enfocándonos en las conductas que hagan la experiencia del bootcamp amena para todos.

                        </p>
                    </div>
                </Col>
            </Row>
                       
        </Container>
    </div>
    )
}

export default Conduct