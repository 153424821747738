// Sección "Acerca de"
import { Container, Row, Col } from 'react-bootstrap'

import img1 from '../images/about-img.png'
import arrowleft from '../images/pleca_izquierda.png'
import arrowright from '../images/pleca_derecha.png'
import form1 from '../images/about-form-pink.png'
import form2 from '../images/about-form-lightblue.png'

import './About.css'

// Animaciones
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

const About = () => {
    return (
        <div id="about">
            <Container>
                <div className="py-4">
                    <Row>
                        <Col lg={ 6 } md={ 6 } sm={ 12 } xs={ 12 } > 
                            <div className="text-lefts about-header" 
                                data-aos="fade-up"
                                data-aos-anchor-placement="center-center">
                                <h1>
                                    <img
                                        src={ arrowleft }
                                        className="arrow"
                                        alt="Bootcamp-Acerca"
                                    />
                                    <span>Acerca _del_</span>
                                </h1>
                                <h1 class="text-right">
                                    <span>Bootcamp_</span>
                                    <img
                                        src={ arrowright }
                                        className="arrow"
                                        alt="Bootcamp-Acerca"
                                    />
                                </h1>
                            </div>
                            <br />
                            <Container fluid>
                                <div className="about-content">
                                    <div>
                                        <Row>
                                            <Col lg={ 2 } md={ 2 } sm={ 2 } xs={ 2 }>
                                                <img
                                                    src={ form1 }
                                                    width="100"
                                                    height="100"
                                                    alt="Bootcamp-Nosotros"
                                                />
                                            </Col>
                                            <Col lg={ 10 } md={ 10 } sm={ 10 } xs={ 10 }>
                                                <p className="text-left font-text">Formación acelerada en las diferentes áreas de la industria del desarrollo de software dirigido a jóvenes y profesionales para que entren al mundo del software y conozcan de las experiencias de líderes de la industria local y latinoamericana.</p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                    <Row>
                                            <Col lg={ 2 } md={ 2 } sm={ 2 } xs={ 2 }>
                                                <img
                                                    src={ form2 }
                                                    width="100"
                                                    height="100"
                                                    alt="Bootcamp-Nosotros"
                                                />
                                            </Col>
                                            <Col lg={ 10 } md={ 10 } sm={ 10 } xs={ 10 }>
                                                <p className="text-left font-text">Dirigido a mujeres, jóvenes, estudiantes de bachillerato, profesionales, empresa privada, universidades, gobierno, empresas de telecomunicaciones, ONG's y más.</p>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Container>
                        </Col>
                        <Col lg={ 6 } md={ 6 } sm={ 12 } xs={ 12 } >
                            <div className="text-right">
                                <img
                                    src={ img1 }
                                    className="w-100"
                                    alt="Formación acelerada en las diferentes áreas de la industria del desarrollo de software"
                                    data-aos="flip-left"
                                    data-aos-easing="ease-out-cubic"
                                    data-aos-duration="2000"
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default About
