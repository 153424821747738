import { Container, Row, Col } from 'react-bootstrap'

import brand from '../images/conexion-white.png'
import facebook from '../images/fb-min.png';
import instagram from '../images/ins-min.png';
import Twitter from '../images/twi-min.png';

import './Footer.css'

const Footer = () => {
    return (
        <div className="bg-dark text-white">
            <footer className="pt-4 text-center">
                <Container>
                    <Row>
                        <Col lg={ 4 } md={ 6 } sm={ 12 } xs={ 12 } className="footerEsp">
                            <img
                                src={ brand }
                                width="150"
                                alt="Somos CONEXION al Desarrollo de El Salvador, una organización sin fines de lucro que promueve el uso y aplicación de las Tecnologías de la Información y Comunicación (TIC), contribuyendo a la reducción de la pobreza y facilitando el desarrollo empresarial."
                            />
                        </Col>
                        <Col lg={ 4 } md={ 6 } sm={ 12 } xs={ 12 } className="footerEsp">
                            <div>2021 - &copy; Todos los derechos reservados</div>
                        </Col>
                        <Col lg={ 4 } md={ 6 } sm={ 12 } xs={ 12 } className="footerEsp">
                            <Container>
                                <Row>
                                    {/* <Col lg={ 4 } md={ 4 } sm={ 4 } xs={ 4 }> */}
                                        <div className="containerRedesImg">
                                            <a href="https://www.facebook.com/conexion.sv">
                                                <img src={facebook} alt="Síguenos en Facebook" width="40%" />
                                            </a>
                                            <a href="https://www.instagram.com/conexion.sv/">
                                                <img src={instagram} alt="Síguenos en instagram" width="40%" />
                                            </a>
                                            <a href="https://twitter.com/CONEXION_SV">
                                                <img src={Twitter} alt="Síguenos en twitter" width="40%" />
                                            </a>
                                        </div>
                                    {/* </Col> */}
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </footer>
            <footer className="py-3">
                <Container>
                    <div className="text-center">Hecho en El Salvador por <a href="http://www.conexion.sv" target="_blank">CONEXION</a></div>
                </Container>
            </footer>
        </div>
    );
}

export default Footer
