// Import React
import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";

// Import JSONP
import jsonp from 'jsonp';

// Importamos las liberias de 'SweetALERT2'
import Swal from 'sweetalert2';

// Importamos los modulos de Bootstrap
import Form from 'react-bootstrap/Form';
import {Button}  from 'react-bootstrap';

import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';

import Typist from 'react-text-typist';

// Importamos el 'CSS'
import './Contact.css';
import './modalFormulario.css';

// Animaciones
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();


const ModalForms = () =>{
    
    var requiredReCAPCHA = '0';
    
    function onChange(value) {
        console.log("Captcha value:", value);
        requiredReCAPCHA = value;
      };


    var  [datos, setDatos] = useState({
        nombre:'',
        apellido:'',
        edad:'',
        gmail:'',
        contacto:'',
        pais:'',
        departamento:'',
        municipio:'',
        ocupacion:'',
        experiencia:'',
        grado:'',
        ACAC:'',
        terminosCondiciones:''
    });

    const onChangeInput = (event) =>{
        // console.log(event.target.value)
        setDatos({
            ...datos,
            [event.target.name] : event.target.value
        });
    };

    const onChangeInputSelect = (event) =>{
        const OP = document.getElementById('OtroPais');
        const DP = document.getElementById('departamento');
        const MO = document.getElementById('OCM');
        if(event.target.value =="El Salvador"){
            OP.style.display = "none";
            DP.style.display = "block";
            MO.style.display = "block";
             datos.pais = event.target.value;
        }else{
            OP.style.display = "block";
            DP.style.display = "none";
            MO.style.display = "none";
               datos.departamento = "-"; 
               datos.municipio = "-"; 
        };
    };

    

    const CheckValue = () =>{
        const check = document.getElementById('terminosCondiciones').value;
        // console.log(datos.terminosCondiciones);
        if(datos.terminosCondiciones == ''){
            datos.terminosCondiciones = true;
        }else{
            datos.terminosCondiciones = false;
        };
    }

    var expReg= /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    const SendData = (event) =>{
        event.preventDefault();

        // document.getElementById("formulario").reset();
        // datos = {  nombre:'', apellido:'', edad:'', gmail:'', contacto:'', pais:'Pais', departamento:'Departamento de residencia', municipio:'', ocupacion:'ocupacion', experiencia:'', grado:'grado', plicacion:'' };

            if(datos.terminosCondiciones == true){
            // console.log("Aqui estamos");
            
              if (expReg.test(datos.gmail)) {

                if( datos.nombre == "" || datos.apellido == ""  || datos.edad == ""  || datos.gmail == "" || datos.contacto == ""  || datos.pais == "" || datos.pais == "Pais" || datos.departamento == "" || datos.departamento == "Departamento de residencia" ||  datos.municipio == ""  || datos.ocupacion == "" || datos.ocupacion == "ocupacion"  || datos.experiencia == ""  || datos.grado == "" || datos.grados == "grado" || datos.ACAC == ""){
        
                    Swal.fire({
                        title: '¡Asegúrate de completar todos los datos del formulario!',
                        showClass: { popup: 'animate__animated animate__fadeInDown' },
                        hideClass: { popup: 'animate__animated animate__fadeOutUp'}
                    })
        
                }else{
                // console.log(datos);

                if(requiredReCAPCHA != '0'){

                    jsonp(`https://gmail.us6.list-manage.com/subscribe/post-json?u=aeb9b11641773d3714851856d&id=ebbb5eb476&NOMBRE=${datos.nombre}&APELLIDO=${datos.apellido}&MMERGE5=${datos.edad}&EMAIL=${datos.gmail}&CONTACTO=${datos.contacto}&PAS=${datos.pais}&DEPARTAMEN=${datos.departamento}&MUNICIPIO=${datos.municipio}&OCUPACIN=${datos.ocupacion}&AOEE=${datos.experiencia}&ACADEMICO=${datos.grado}&CC=${datos.ACAC}&c=__jp5`, { param: 'c' }, (err, data) => {
                        // console.log(err);
                        // console.log(data);
                    if(data.msg == "Thank you for subscribing!" ){
                            Swal.fire({
                                    title: '¡Gracias por registrarte! Mantente pendiente de tu correo electrónico para saber si recibiste la beca.',
                                    showClass: {popup: 'animate__animated animate__fadeInDown'},
                                    hideClass: {popup: 'animate__animated animate__fadeOutUp'}
                            });
                            
                                document.getElementById("formulario").reset();
                                datos = {  nombre:'', apellido:'', edad:'', gmail:'', contacto:'', pais:'Pais', departamento:'Departamento de residencia', municipio:'', ocupacion:'ocupacion', experiencia:'', grado:'grado', plicacion:'' };
                  
                                // setTimeout(function(){ 
                                //     window.location.replace('');
                                // }, 4000);

                    }else{
                        Swal.fire({
                            title: '¡Este correo ya se registró previamente!',
                            showClass: { popup: 'animate__animated animate__fadeInDown' },
                            hideClass: { popup: 'animate__animated animate__fadeOutUp' }
                        });
                            
                        
                            document.getElementById("formulario").reset();
                            datos = {  nombre:'', apellido:'', edad:'', gmail:'', contacto:'', pais:'Pais', departamento:'Departamento de residencia', municipio:'', ocupacion:'ocupacion', experiencia:'', grado:'grado', plicacion:'' };

                            // setTimeout(function(){ 
                            //     window.location.replace('');
                            // }, 3500);
                        };
                    });
            
                }else{
                    Swal.fire({
                        title: '¡ reCaptcha required !',
                        showClass: { popup: 'animate__animated animate__fadeInDown' },
                        hideClass: { popup: 'animate__animated animate__fadeOutUp' }
                    });
                }

        
                };

        }else{
            Swal.fire({
                title: '¡Asegúrate de escribir un correo válido!',
                showClass: { popup: 'animate__animated animate__fadeInDown' },
                hideClass: { popup: 'animate__animated animate__fadeOutUp' }
            });
        }
        
        }else{
            Swal.fire({
                title: '¡Debes de aceptar los términos y condiciones !',
                showClass: { popup: 'animate__animated animate__fadeInDown' },
                hideClass: { popup: 'animate__animated animate__fadeOutUp' }
            });
        }
    
    }

    const [smShow, setSmShow] = useState(false);
    const [lgShow, setLgShow] = useState(false);

    return(
        <div> 
                    <Button  onClick={() => setLgShow(true)} className="btn btn-register">Regístrate</Button>
                    <Modal
                        size="lg"
                        show={lgShow}
                        onHide={() => setLgShow(false)}
                        backdrop="static"
                        aria-labelledby="example-modal-sizes-title-lg"
                    >
                    <Modal.Body>
                     
        <Modal.Header closeButton></Modal.Header>
                        <div className="tituloModal">
                            <h1 className="tituloSectionForm"> 
                            <span>Aplica y regístrate al_</span>
                                    <br/>
                            <span className="textBootCampForm">Bootcamp_</span>
                            </h1>
                              <div className="segundoTitulo">
                        <h5>Completa <span className="textDatos">
                            <Typist
                                sentences={[
                                    'la información',
                                    'el formulario',
                                    'tus datos'
                                ]}
                                typingSpeed={250}
                            />
                            </span>
                        </h5>
                    </div>
                        </div>
                           <Form id="formulario">
                                <Form.Group className="mb-3">
                                    <Form.Control onChange={onChangeInput} type="text" placeholder="Nombre" name="nombre" id="nombre"  />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Control onChange={onChangeInput} type="text" placeholder="Apellido" name="apellido" id="apellido"  />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Control onChange={onChangeInput} type="number" placeholder="Edad" name="edad" id="edad" />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Control onChange={onChangeInput} type="email" placeholder="Correo electrónico"name="gmail" id="gmail"  />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Control onChange={onChangeInput} type="number" placeholder="Teléfono de contacto " name="contacto" id="contacto" />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Control onChange={onChangeInputSelect} as="select" custom >
                                        <option selected value="Pais" >País</option>
                                        <option value="El Salvador"> El Salvador</option>
                                        <option value="Otro">Otro</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group className="mb-3 inputOtroPais" id="OtroPais">
                                    <Form.Control onChange={onChangeInput}  type="text" placeholder="Escribe el país de donde eres " name="pais" id="pais" />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Control onChange={onChangeInput} as="select" custom name="departamento" id="departamento" >
                                        <option selected  value="Departamento de residencia"  >Departamento de residencia</option>
                                        <option value="Ahuachapán">Ahuachapán</option>
                                        <option value="Cabañas">Cabañas</option>
                                        <option value="Chalatenango">Chalatenango</option>
                                        <option value="Cuscatlán"> Cuscatlán</option>
                                        <option value="La Libertad"> La Libertad</option>
                                        <option value="La Paz"> La Paz</option>
                                        <option value="La Unión"> La Unión</option>
                                        <option value="Morazán"> Morazán</option>
                                        <option value="San Miguel"> San Miguel</option>
                                        <option value="San Salvador"> San Salvador</option>
                                        <option value="San Vicente"> San Vicente</option>
                                        <option value="Santa Ana"> Santa Ana</option>
                                        <option value="Sonsonate"> Sonsonate</option>
                                        <option value="Usulután"> Usulután</option>
                                    </Form.Control>
                                </Form.Group>
                               
                                <Form.Group className="mb-3" id="OCM">
                                    <Form.Control onChange={onChangeInput} type="text" placeholder="Municipio de residencia" name="municipio" id="municipio"  />
                                </Form.Group>
                       
                                <Form.Group className="mb-3">
                                    <Form.Control onChange={onChangeInput} as="select" custom name="ocupacion" id="ocupacion"  >
                                        <option selected  value="Ocupación" >Ocupación</option>
                                        <option value="Estudiante">Estudiante</option>
                                        <option value="Empleado, Empresario o Emprendedor">Empleado, Empresario o Emprendedor</option>
                                        <option value="Estudiante y Empleado, Empresario o  Emprendedor">Estudiante y Empleado, Empresario o  Emprendedor</option>
                                        <option value="Freelance o Independiente"> Freelance o Independiente</option>
                                        <option value="Desempleado"> Desempleado</option>
                                    </Form.Control>
                                 </Form.Group>
                                     
                                 <Form.Group className="mb-3">
                                    <Form.Control onChange={onChangeInput} type="text" placeholder="Área de ocupación, experiencia o estudio" name="experiencia" id="experiencia"  />
                                 </Form.Group>

                                 <Form.Group className="mb-3">
                                    <Form.Control onChange={onChangeInput} as="select" custom name="grado" id="grado"  >
                                        <option selected value="Último grado académico" >Último grado académico</option>
                                        <option value="Educación Básica">Educación Básica</option>
                                        <option value="Bachiller">Bachiller</option>
                                        <option value="Técnico">Técnico</option>
                                        <option value="Universitario"> Universitario</option>
                                        <option value="Maestría"> Maestría</option>
                                        <option value="Doctorado"> Doctorado</option>
                                    </Form.Control>
                                 </Form.Group>

                                 <Form.Group className="mb-3">
                                    <Form.Control onChange={onChangeInput}  type="text" placeholder="¿Cómo aplicarás el conocimiento adquirido en el bootcamp? " name="ACAC" id="ACAC" />
                                 </Form.Group>
                    
                                 <Form.Group>
                                 <div 
                                   >
                                        <Form.Check
                                        className="CheckForm"
                                            type="checkbox"
                                            label="Si soy seleccionada(o), me comprometo en asistir a todas las sesiones del Bootcamp."
                                            name="terminosCondiciones"
                                            id="terminosCondiciones"
                                            onClick={CheckValue}
                                            required
                                        />
                                        <p className="usoDeDatos">
                                        <br/>
                                        <div className="d-flex justify-content-center">
                                 <ReCAPTCHA
                                    sitekey="6LchBsEbAAAAAPpzTXIAgyA6Nrht6vNnceaS9zJd"
                                    onChange={onChange}
                                    
                                    />
                                 </div>
                                 <br/>
                                        <strong>Información básica sobre protección de datos:</strong> La finalidad es contactar contigo para informarte sobre las oportunidades de capacitaciones y becas que ofrecemos con nuestros proyectos y programas. No cederemos datos a terceros.
                                        </p>
                                        <p 
                                        className="text-center buttonResponsiveForm">
                                            <Button className="btn btn-register" type="submit" 
                                        onClick={SendData} >Aplica y regístrate</Button>
                                        </p>
                                 </div>
                                 </Form.Group>
                            </Form>
                        </Modal.Body>
                     </Modal>
        </div>
    )
}

export default ModalForms