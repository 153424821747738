// Import React
import React from 'react';

import ModalForms from './modalFormulario';

// Importamos los modulos de Bootstrap
import { Container, Row, Col } from 'react-bootstrap';

// Importamos el 'CSS'
import './Contact.css';

// Animaciones
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();


const FormSection = () =>{

    return(
        <div className="bgForm" id="contact">
            <Container> 
                 <div>
                    {/* <h1 className="tituloSectionForm"> 
                        <span>Aplica al_</span>
                            <br/>
                        <span className="textBootCampForm">Bootcamp_</span>
                    </h1> */}
                    <div className="segundoTitulo">
                        {/* <h5>Completa <span className="textDatos"> */}
                            {/* <Typist
                                sentences={[
                                    'la información',
                                    'el formulario',
                                    'tus datos'
                                ]}
                                typingSpeed={250}
                            /> */}
                            {/* </span> */}
                        {/* </h5> */}
                    </div>
                </div>
                <Row>
                    <Col md={1}></Col>
                    <Col md={10}>
                        <p 
                        className="text-center buttonResponsiveForm">
                            {/* <ModalForms /> */}
                        </p> 

                    </Col>
                    <Col md={1}></Col>
                </Row>
            </Container>
        </div>
    )
}

export default FormSection