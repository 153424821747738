// Componentes
import Navigation from './components/Navigation'
import Header from './components/Header'
import About from './components/About'
import Footer from './components/Footer' 

import ProgramBootCamp from './components/Program'
import Agenda from './components/Schedule'
import FormSection from './components/Contact'
import Beca from './components/Scholarship'
import Sponsors from './components/Sponsors'
import Conduct from './components/CodeOfConduct'
import Premios from './components/premios'
import Message from './components/Cookie'

import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
	return (
		<div>
			<Navigation />
			<Message />
			<Header />
			<About />
			<ProgramBootCamp/>
			<Agenda/>
			<Premios/>
			<Conduct/>
			<Beca/>
			<FormSection/>
			<Sponsors/>
			<Footer />
		</div>
	);
}

export default App;
