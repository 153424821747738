// Sección "Adquiere Becas"
import {Container,Row,Col} from 'react-bootstrap'
import './Scholarship.css'
import imgBeca from '../images/imagen_becas-min.png'
import fechaIzquierda from './../images/pleca_izquierda-min.png';
import fechaDerecha from './../images/pleca_derecho-min.png';

import form1 from '../images/about-form-pink.png'

import arrowleft from '../images/pleca_izquierda.png'
import arrowright from '../images/pleca_derecha.png'
// Animaciones
import AOS from 'aos';
import 'aos/dist/aos.css';
const AdquiereUnsBeca = () =>{
    return(
        <div className="bgBeca" id="scholarship">
            <Container>
                <div className="contenedorInformcacion">
                    <Row>
                        <Col md={6}>
                                <img src={imgBeca} className="img-fluid imgBeca" alt="Beca ticongle Bootcamp"/>
                        </Col>
                        <Col md={6}>
                            
                        <div>
                        <div className="text-lefts about-header" 
                                data-aos="fade-up"
                                data-aos-anchor-placement="center-center">
                                <h1>
                                    <img
                                        src={ arrowleft }
                                        className="arrowBeca"
                                        alt="Beca ticongle Bootcamp"
                                    />
                                    <span className="tituloBecaT" >Obtén_</span>
                                </h1>
                                <h1 class="text-right">
                                    <span className="tituloBecaT">una_beca_</span>
                                    <img
                                        src={ arrowright }
                                        className="arrowBeca"
                                        alt="Beca ticongle Bootcamp"
                                    />
                                </h1>
                            </div>
                            {/* <h1 className="tituloSectionBeca"> 
                                <span>  <img src={fechaIzquierda} className="imgFechasBeca" alt=""/>Adquiera_</span>
                                    <br/>
                                <span className="textBootCampBeca">una_beca_  <img src={fechaDerecha} className="imgFechasBeca" /></span>
                            </h1> */}
                        </div>

                            <div className="informacionDivBeca"
                             data-aos="fade-down"
                             data-aos-easing="linear"
                             data-aos-duration="1500"
                            >
                                <Row>
                                    <Col md={6}>
                                       <p className="datosBecas"> 20+</p>
                                       <span className="datosBecas2">Horas de formación</span>
                                    </Col>
                                    <Col md={6}>
                                    <img
                                                    src={ form1 }
                                                    width="100"
                                                    height="100"
                                                    alt="Beca ticongle Bootcamp"
                                                    className="imgLineaTextpBecas"
                                                />
                                    <p className="datosBecas">6+</p>
                                      <span className="datosBecas2">Áreas de formación </span>
                                    
                                    </Col>
                                    <Col md={6}>
                                    <p className="datosBecas"> 1,000+</p>
                                      <span className="datosBecas2">Participantes</span>
                                    </Col>
                                    <Col md={6}>
                                        <p className="datosBecas">20+
                                        </p>
                                        <span className="datosBecas2">Expertos en desarrollo web </span>
                                       
                                    </Col>
                                </Row>
                            </div>    
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default AdquiereUnsBeca