import CookieConsent from 'react-cookie-consent'

import './Cookie.css'

const Cookie = () => {
    return (
        <div>
            {/* <CookieConsent
                debug={true}
                location="bottom"
                buttonText="Entendido"
                buttonClasses="btn btn-register"
                cookieName="Bootcamp"
                style={{ background: "#121212" }}
                buttonStyle={{ color: "#4e503b", fontSize: "20px" }}
                expires={350}
            >
                Utilizamos cookies propias para mejorar la experiencia del usuario a través de su navegación. Si continúas navegando aceptas su uso.
            </CookieConsent> */}
        </div>
    );
}

export default Cookie
